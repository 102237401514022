.test {
    background-color: pink;
}

/* Scroll Animations */
.hidden {
    opacity: 0;
    transition: all 1s;
}

.show {
    opacity: 1;
}